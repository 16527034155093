import { Box, styled, useMediaQuery } from '@mui/material';

import { ElementType, FC, ReactNode } from 'react';

import { ExceptionIcon } from './icon';
import { Link } from './Link';
import { Text } from './Text';

export const numericCodes = {
  BAD_REQUEST: 'VW0018',
  DOCUMENT_NOT_FOUND: 'VW0004',
  EMAIL_SEND: 'VW0017',
  ENCRYPT: 'VW0009',
  FORBIDDEN: 'VW0019',
  INVALID_ARG: 'VW0013',
  INVALID_ENV_VAR: 'VW0014',
  INVALID_RESPONSE: 'VW0020',
  INVALID_TOKEN: 'VW0015',
  MISSING_CONTEXT: 'VW0006',
  MISSING_COOKIE: 'VW0016',
  MISSING_ENV_VAR: 'VW0008',
  MISSING_PARAMETER: 'VW0005',
  MISSING_RELATIONSHIP: 'VW0007',
  NOT_FOUND: 'VW0003',
  PARTICIPANT_REGISTRATION_ADDRESS_VERIFY: 'VW0103',
  PARTICIPANT_REGISTRATION_COMPANY_VERIFY: 'VW0104',
  // preserve sub-100 range for general errors
  PARTICIPANT_REGISTRATION_EXISTS: 'VW0100',
  PARTICIPANT_REGISTRATION_LOCKOUT: 'VW0105',
  PARTICIPANT_REGISTRATION_NO_MATCH: 'VW0101',
  PARTICIPANT_REGISTRATION_PARTIAL_MATCH: 'VW0102',
  PARTICIPANT_REGISTRATION_USER_EXISTS: 'VW0106',
  SPONSOR_REGISTRATION_EXISTS: 'VW0108',
  SPONSOR_REGISTRATION_LOCKOUT: 'VW0107',
  SPONSOR_REGISTRATION_NO_MATCH: 'VW0109',
  SPONSOR_REGISTRATION_PARTIAL_MATCH: 'VW0110',
  SPONSOR_REGISTRATION_USER_EXISTS: 'VW0111',
  TOKEN_DECRYPT: 'VW0010',
  TOKEN_EXPIRED: 'VW0011',
  TOKEN_VERIFY: 'VW0012',
  UNAUTHORIZED: 'VW0002',
  UNKNOWN_EXCEPTION: 'VW0001'
};

export type ErrorProps = {
  children?: ReactNode;
  /** Numeric error code */
  code?: string;
  /** ID on details DOM element */
  descriptionId?: string;
  /** Text to use for details */
  details?: ReactNode;
  icon?: ElementType;
  /** ID on title DOM element */
  labelId?: string;
  /** Override component name */
  name?: string;
  /** Support email address to show on error messaging. */
  supportEmail?: string;
  /** Text to use for title */
  title?: ReactNode;
};

const StyledExceptionIcon = styled(ExceptionIcon)(({ theme }) => ({
  color: theme.palette.dijon.main,
  height: theme.spacing(32),
  marginBottom: theme.spacing(4),
  width: theme.spacing(32)
}));

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(8)
}));

export const Error: FC<ErrorProps> = ({
  icon = StyledExceptionIcon,
  name = 'Error',
  ...props
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const Icon = icon;

  return (
    <Box
      data-component={name}
      padding={8}
      role='presentation'
      textAlign='center'>
      <Icon color='dijon' />
      {props.title && (
        <Text
          color='black'
          component='h1'
          data-testid={`${name}__title`}
          id={props.labelId}
          marginX='auto'
          mt={isMobile ? 8 : 12}
          variant={isMobile ? 'e2' : 'b2'}>
          {props.title}
        </Text>
      )}
      {props.details && (
        <Text
          color='grey100'
          component='div'
          data-testid={`${name}__details`}
          id={props.descriptionId}
          marginX='auto'
          mb={isMobile ? 16 : undefined}
          mt={isMobile ? 8 : 6}
          textAlign={isMobile ? 'left' : undefined}>
          {props.details}
        </Text>
      )}
      {props.supportEmail && (
        <StyledLink
          data-testid='Error__supportEmail'
          to={`mailto:${props.supportEmail}`}>
          {props.supportEmail}
        </StyledLink>
      )}
      {props.children && (
        <Box data-testid='Error__children' mt={10}>
          {props.children}
        </Box>
      )}
      {props.code && (
        <Text
          color='grey300'
          component='code'
          data-testid='Error_code'
          display='block'
          mt={6}
          variant='i1'>
          Error Code: {numericCodes[props.code] || 'UNKNOWN_EXCEPTION'}
        </Text>
      )}
    </Box>
  );
};

Error.displayName = 'Error';
