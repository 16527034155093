import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import React, { useEffect, useState } from 'react';

export function useSecureStorage(
  key
): [string | null, React.Dispatch<React.SetStateAction<string | null>>] {
  const [secureStorageValue, setSecureStorageValue] = useState<string | null>(
    null
  );

  const setSecureStorageValueInStorage = async (value: string | null) => {
    await SecureStoragePlugin.set({ key, value });
    return setSecureStorageValue(value);
  };

  const getSecureStorageValue = async () => {
    try {
      // Values to store will come from the webpack globals
      const result = await SecureStoragePlugin.get({ key });
      setSecureStorageValue(result?.value);
    } catch (e) {
      console.warn('SecureStoragePlugin.get failed', e);
    }
  };

  useEffect(() => {
    getSecureStorageValue();
  }, [secureStorageValue, setSecureStorageValue, key]);

  return [secureStorageValue, setSecureStorageValueInStorage];
}
