import { styled } from '@mui/material';
import { logout } from '@vestwell-frontend/helpers';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  TimerIcon
} from '@vestwell-frontend/ui';

import { FC, ReactNode, useCallback, useId, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useInterval, useLocation, useToggle } from 'react-use';

const timeout = {
  advisor: 3600 * 1000,
  participant: 900 * 1000,
  sponsor: 900 * 1000,
  user: 900 * 1000
};

const promptBeforeIdle = {
  advisor: 60 * 1000,
  participant: 60 * 1000,
  sponsor: 60 * 1000,
  user: 60 * 1000
};

const StyledTimerIcon = styled(TimerIcon)(({ theme }) => ({
  fill: 'none',
  height: theme.spacing(30.25),
  width: theme.spacing(30)
}));

export const TimeoutModal: FC<{
  children: ReactNode;
  entity: 'advisor' | 'sponsor' | 'participant' | 'user';
}> = props => {
  const headerId = useId();

  const location = useLocation();

  const originalTitle = useMemo(() => document.title, [location.pathname]);

  const [isModal, toggleModal] = useToggle(false);

  const timer = useIdleTimer({
    crossTab: true,
    onIdle: () => logout(true),
    onPrompt: toggleModal,
    promptBeforeIdle: promptBeforeIdle[props.entity],
    timeout: timeout[props.entity]
  });

  const onHere = useCallback(() => {
    document.title = originalTitle;
    timer.activate();
    toggleModal();
  }, []);

  useInterval(() => {
    const remainingMs = timer.getRemainingTime();
    const remainingSeconds = Math.floor(remainingMs / 1000);

    if (remainingMs < promptBeforeIdle[props.entity]) {
      document.title = `00:${
        remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
      } in session remaining`;
    }
  }, 1000);

  return (
    <>
      <Modal
        aria-labelledby={headerId}
        data-testid='timeout'
        locked={isModal}
        onRequestClose={toggleModal}
        overlayColor='white'>
        <ModalBody>
          <div className='w-100 flex justify-center'>
            <StyledTimerIcon />
          </div>
          <Text
            color='oxfordBlue'
            id={headerId}
            textAlign='center'
            variant='b2'>
            Your Session is About to Timeout
          </Text>
          <Text color='black' textAlign='center' variant='g1'>
            If you take no action, you will be logged out momentarily
          </Text>
        </ModalBody>
        <ModalFooter justifyBetween>
          <Button onClick={logout} variant='outlined'>
            Log Out
          </Button>
          <Button onClick={onHere} variant='contained'>
            I'm still here
          </Button>
        </ModalFooter>
      </Modal>
      {props.children}
    </>
  );
};

TimeoutModal.displayName = 'TimeoutModal';
