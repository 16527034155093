import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import Cookies from 'js-cookie';

import { isNativeMobile } from './isNativeMobile';

export const cleanUpStaleCookies = () => {
  const attributes = {
    domain: `.${window.location.host.split('.').slice(-2).join('.')}`,
    path: '/'
  };
  Cookies.remove('isLoginDisclosure', attributes);
  Cookies.remove('token', attributes);
  Cookies.remove('tokenExpiration', attributes);
  Cookies.remove('refreshToken', attributes);
};

export const logout = async (timeout?) => {
  const token = Cookies.get('token');
  const isMobile = await isNativeMobile();
  cleanUpStaleCookies();

  try {
    await fetch('/auth/api/v2/logout', {
      headers: {
        Authorization: token
      },
      method: 'GET'
    });
    if (!isMobile) {
      window.location.href = `${window.location.origin}${timeout === true ? `?error=TIMEOUT` : ''}`;
    } else {
      await SecureStoragePlugin.remove({ key: 'token' });
      window.location.assign(
        `capacitor://localhost/${timeout === true ? `?error=TIMEOUT` : ''}`
      );
    }
  } catch (e) {
    console.error('Failed to logout', e);
    window.location.href = window.location.origin;
  }
};
